.contentWrap {
  width: 50%;
  margin-left: 20%;
  @media (max-width: 1300px) {
    width: 70%;
    margin-left: 15%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0%;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.card {
  height: calc(100vh - 350px);
  overflow: hidden;
  > :global(.ant-card-body) {
    height: 100%;
    padding: 4px 24px;
  }
  :global(.ant-table-tbody > tr > td) {
    padding: 8px 16px;
  }
}

.space {
  width: 100%;
}

.search {
  width: 60%;
  @media (max-width: 1000px) {
    width: 100%;
  }
}
