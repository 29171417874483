@import 'styles/mixins.scss';
@import 'styles/values.scss';

.input {
  border-color: transparent;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09),
    0px 2px 16px rgba(204, 188, 252, 0.15);
  padding: 8px 15px;
  ::placeholder {
    color: #e0e0e0;
  }
  &:focus-within {
    border-color: $color-main;
  }
}

.icon {
  color: rgba(186, 186, 186, 0.45);
  @include fontSize(18px);
}
