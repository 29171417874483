@import 'styles/mixins.scss';
@import 'styles/values.scss';

$space-card-margin: 12px;

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
}

.buildingSpaceCardContainer {
  width: 100%;
  padding: 12px 0;
  overflow-y: auto;
}

.spaceCard {
  padding-right: 1.7rem;
  margin: $space-card-margin;
  width: calc(50% - $space-card-margin * 2);

  @media (max-width: $location-tray-width-breakpoint) {
    width: calc(100% - $space-card-margin * 2);
  }
}

.icon {
  @include fontSize(48px);
  width: 100%;
}

.emptyLabel {
  @include fontSize(20px);
  color: #7a838c;
  font-weight: 600;
}
