@import 'views/Settings/styles.module.scss';

.card {
  margin-bottom: $content-margin;
}

.contentWrap {
  width: 50%;
  margin-left: 20%;
  overflow: visible;
  @media (max-width: 1300px) {
    width: 70%;
    margin-left: 15%;
  }
  @media (max-width: 1000px) {
    width: 99%;
    margin-left: 0%;
  }
}

.newButtonIcon {
  transform: translate(-3px, -1px);
}
