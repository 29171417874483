@import 'styles/mixins.scss';
@import 'styles/values.scss';

.navbar {
  background: $color-background-white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid $color-background-gray;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  padding: 8px 50px;

  position: sticky;
  z-index: 1;
  width: 100%;
}

.columnMid {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.columnRight {
  display: flex;
  justify-content: flex-end;
}

.logoutButton {
  :global(svg) {
    margin-right: 5px;
  }
}
