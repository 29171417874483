@import '../values.scss';

.ant-table-row {
  user-select: none;
  &.isActive {
    background-color: $color-main;
    color: #fff;

    td {
      background: none;
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: darken($color-main, 5%);
  color: #fff;
  .ant-btn-link {
    color: #fff;
  }
}
