@import 'styles/values.scss';

.ico {
  vertical-align: middle;
  margin-right: 10px;
  svg {
    fill: $color-main;
    width: 30px;
    height: 30px;
  }
}

.wrapper {
  position: relative;
  height: 100%;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
