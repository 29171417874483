@import 'styles/mixins.scss';
@import 'styles/values.scss';

.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1.2rem;
  height: 100%;
  :global {
    h4.ant-typography {
      align-self: center;
      color: $color-text-dark;
      padding-bottom: 1px;
      margin: 0px;
    }
    h5.ant-typography {
      font-weight: 300;
      color: $color-text-dark;
    }
  }
}

.iconCenter {
  @include fontSize(38pt);
  color: $color-main;
  padding-bottom: 4px;

  &.noBuildings {
    padding-bottom: 32px;
  }
}

.stateLabel {
  align-self: center;
  padding-bottom: 1px;
}

.filterBuildingsInput {
  align-self: center;
  margin: 16px 16px;
  width: 60%;
}

.createBuildingButton {
  align-self: center;
  margin-top: 10px;
  margin-bottom: 22px;

  &.noBuildings {
    margin-bottom: 0;
    margin-top: 24px;
  }
}

.buildingCollectionContainer {
  overflow-y: auto;

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: $color-graphic-gray;
    width: 8px;
    border-radius: 8px;
  }
}
