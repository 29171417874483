@import 'styles/mixins.scss';
@import 'styles/values.scss';

$content-width: 950px;
$content-margin: 20px;
$slider-width: 260px;

.layout {
  height: 100%;
}

.slider {
  padding: $content-margin;
  padding-left: 26px;
  overflow-y: auto;
  overflow-x: visible;
  &:global(.ant-layout-sider) {
    background-color: transparent;
    @media (max-width: 992px) {
      padding: $content-margin;
      margin-left: $content-margin;
    }
  }
}

.menu {
  &:global(.ant-menu) {
    background-color: transparent;
    :global(.ant-menu-item) {
      border-radius: 20px;
      &:global(.ant-menu-item-selected) {
        background-color: $color-background-white;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
      }
      &:after {
        display: none;
      }
      &:active {
        background-color: transparent;
      }
    }
  }
}

.content {
  overflow-y: auto;
  overflow-x: visible;
  padding: $content-margin ($slider-width + $content-margin) $content-margin
    $content-margin;
  display: flex;
  justify-content: center;
  > * {
    min-width: $content-width;
    @media (max-width: 1522px) {
      min-width: 100%;
    }
  }
  @media (max-width: 1522px) {
    padding-right: $content-margin;
  }
}

// ugly fix for lang dropdown
.lang {
  :global(.ant-menu-title-content) {
    overflow: visible;
    :global(.ant-dropdown-trigger) {
      margin-left: -30px;
      padding-left: 30px;
    }
  }
}

:export {
  sliderWidth: $slider-width;
}
