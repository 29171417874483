@import 'styles/mixins.scss';
@import 'styles/values.scss';

.wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 210mm * 1.4; // A4
  transform: translateX(-100%);
  background-color: $color-background-gray;
}
