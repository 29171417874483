@import 'styles/values';

$border-radius: 5px;

.container {
  background-color: $color-background-white;
  border-radius: $border-radius;
  border: 1px solid $color-border-gray;

  > * {
    &:first-child {
      :global(button.ant-btn) {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }
    &:last-child {
      :global(button.ant-btn) {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  button {
    &:hover {
      z-index: 1;
    }
    &:global(.ant-btn) {
      border: none;
      box-shadow: none;
      border-radius: 0;
      &:disabled {
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
