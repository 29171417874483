@keyframes fadeIn {
  from {
    opacity: 0;
    filter: blur(10px);
  }
  to {
    opacity: 1;
    filter: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    filter: none;
  }
  to {
    opacity: 0;
    filter: blur(10px);
  }
}

.fade {
  height: 100%;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
}

.fadeIn {
  @extend .fade;
  animation-name: fadeIn;
}

.fadeOut {
  @extend .fade;
  animation-name: fadeOut;
}
