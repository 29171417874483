.buttonsWrap {
  flex-wrap: wrap;
  justify-content: center;
}

.text {
  position: relative;
  z-index: 1;
}

.noImportersLabel {
  &:global(.ant-typography) {
    margin-bottom: 0;
  }
}
