@import 'styles/mixins.scss';
@import 'styles/values.scss';

.locationTray {
  display: flex;
  flex-direction: column;
  height: 80vh;
  min-height: 200px;
  max-width: 90vw;
  width: 860px;

  @media (max-width: $location-tray-width-breakpoint) {
    max-width: 547px;
  }

  :global(.ant-spin-container) {
    height: 100%;
  }

  :global(.ant-spin) {
    max-height: unset !important;
  }
}

.locationTrayCaption {
  width: 100%;
  padding: 8px 0 0 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 16px;
  color: $color-text-dark;
}

.icon {
  @include fontSize(28px);
  color: $color-main;
  padding: 0 12px;
}

.locationTrayBody {
  height: 100%;
  width: 100%;
  padding: 8px 8px 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow-y: auto;
}

.captionCenterContainer {
  background: wheat;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
