@import 'styles/mixins.scss';
@import 'styles/values.scss';

.formTitle {
  h4.title {
    font-size: 14px;
    font-weight: 600;
    color: $color-text-dark;

    span {
      vertical-align: top;
    }
  }

  .setupIcon {
    @include fontSize(24px);
    margin-right: 15px;
    margin-left: 8px;
    margin-bottom: 8px;
    color: $color-main;
  }
}
