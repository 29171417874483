@import 'styles/mixins.scss';
@import 'styles/values.scss';

.divider {
  width: 100%;
  margin: 0;
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: $location-tray-width-breakpoint) {
    padding: 10px 20px 0px 20px;
  }

  .buildingAvatar {
    width: 46px;
    height: 48px;
    line-height: 46px;
    @include fontSize(28px);
  }

  .icon {
    margin-top: 10px;
  }

  .buildingLabels {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 15px;
    margin-top: 0px;
    font-weight: 100;

    :first-child {
      font-size: 16px;
      font-weight: 200;
      color: #000;
      @include fontSize(16px);
    }
    :last-child {
      @include fontSize(13px);
      padding-left: 0px;
      color: $color-text-light;
    }

    :global(.ant-typography) {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 157px;
      overflow-x: hidden;
    }
  }

  .buildingStat {
    display: flex;
    flex-direction: column;
    padding: 1px 25px;
    font-size: 16px;
    font-weight: 400;

    :first-child {
      @include fontSize(14px);
      color: $color-text-light;
    }
    :last-child {
      @include fontSize(16px);
    }
  }
}
