$space: 10px;

.columnCardWrapper {
  padding: $space;
  break-inside: avoid;
  width: 100%;
}

.container {
  margin: -#{$space};
  margin-top: 0;
  gap: 0;
  width: calc(100% + $space * 2);
  column-count: var(--columns, 1);
}
