.modal {
  padding-bottom: 0;
  :global(.ant-modal-body) {
    overflow: auto;
    max-height: calc(100vh - 180px);
  }
}

.backgroundClickable {
  pointer-events: none;
}
