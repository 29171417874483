@import 'styles/mixins.scss';
@import 'styles/values.scss';

$ls-forms-title-height: 3em;
$buttons-width: 16px;

.container {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0 10px;

  :global {
    .fade-enter {
      opacity: 0;
    }
    .fade-exit {
      opacity: 1;
    }
    .fade-enter-active {
      opacity: 1;
    }
    .fade-exit-active {
      opacity: 0;
    }
    .fade-enter-active,
    .fade-exit-active {
      transition: opacity 0.15s;
    }
  }
}

.form {
  flex: 1 1;
  height: 100%;
  width: 100%;
  position: relative;

  .formScroll {
    height: 100%;
    padding-left: 3rem;
    padding-right: 4.25rem;
  }
}

.formCard {
  grid-area: form;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  overflow-y: auto;
  height: 100%;

  @media (max-width: $location-tray-width-breakpoint) {
    box-shadow: none;
  }
}

.subHeader {
  font-size: 13.5px;
  font-weight: 600;
  color: $color-text-dark;
  &:global(.ant-typography) {
    margin-bottom: 6px;
  }
  :global(.ant-form-item-label > label) {
    color: $color-text-dark;
  }
}

.scrollableArea {
  height: 100%;
  overflow: auto;
  padding-right: 30px;
  margin-bottom: 20px;
}

.formButton {
  margin: 10px;
  min-width: 100px;
}

.deleteButton {
  color: $color-danger;
  width: $buttons-width;
}

.addButton {
  color: $color-success;
  width: $buttons-width;
}

h4.title {
  font-size: 14px;
  font-weight: 600;
  color: $color-text-dark;

  span {
    vertical-align: top;
  }
}

.formSubmitRow {
  position: relative;
}
