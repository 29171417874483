@import 'styles/values';

$border-radius: 5px;

.container {
  color: $color-text-dark;
  background-color: $color-background-white;
  border: 1px solid $color-border-gray;
  padding: 5px 8px;
  border-radius: $border-radius;
}
