@import 'styles/mixins.scss';
@import 'styles/values.scss';

$border-radius: 8px;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  width: 100%;
  min-height: 240px;
  border: 1px dashed $color-border-gray;
  border-radius: $border-radius;
  transition: all 0.15s ease-in-out;
  cursor: pointer;

  &.active {
    border-color: darken($color-border-gray, 20%);
    background-color: lighten($color-border-gray, 9%);
  }

  :global(.ant-typography + *.ant-typography) {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
}

.title {
  &:global(.ant-typography) {
    color: $color-text-dark;
    font-weight: bold;
  }
}

.icon {
  @include fontSize(64px);
  color: $color-text-dark;
  margin-bottom: 20px;
}

.button {
  margin-top: 20px;
}
