.reportConfigList {
  height: calc(100% - 20px);
  overflow: hidden;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.tableTop {
  margin-bottom: 20px;
}

.squareTypeIndicator {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  margin: 0 8px;
  vertical-align: middle;
}

.empty > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
