.deviceName {
  text-align: center;
}

.deviceLocation {
  font-size: 12px;
  text-align: center;
  margin-bottom: 6px;
}

.deviceDetailsWrapper {
  :global(.ant-typography + h5.ant-typography) {
    margin-top: 0;
    font-size: 12px;
  }
  :global(h3.ant-typography) {
    margin-bottom: 0;
  }
}

.deviceStatus {
  text-align: center;
}

.tilesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.centeredSpace {
  width: 100%;
  justify-content: center;
}

.attributeName {
  font-weight: bold;
  text-align: right;
  display: inline-block;
  width: 40%;
}

.attributeValue {
  text-align: left;
  display: inline-block;
  width: 55%;
}
