@import 'styles/mixins.scss';
@import 'styles/values.scss';

.layout {
  height: 100%;
}

.header {
  background: $color-background-white;
}

.logo {
  @include fontSize(31px);
  line-height: normal;
  vertical-align: middle;
}
