.content {
  margin: 5px 0;
  max-height: calc(100vh - 360px);
  overflow: hidden;
  :global(.ant-card) {
    height: calc(100vh - 360px);
  }
  :global(.ant-table-tbody > tr > td) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
