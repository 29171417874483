.modalTitle {
  h4 {
    margin-bottom: 0;
  }
}

.downloadTableWrapper {
  height: calc(100% - 20px);
  overflow: hidden;

  &.emptyBorder tr > td {
    border-bottom: none;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
