@import 'styles/values.scss';

.figure {
  position: absolute;
  margin: 0;
  pointer-events: none;
  p {
    width: var(--size);
    height: calc(var(--size) - var(--size) / 1.5);
    background-color: desaturate($color-main, 30%);
    margin: 0;
    position: absolute;
    border-radius: var(--size);
  }
}

.plus {
  transform: rotate(90deg);
}
