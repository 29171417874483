.ant-modal-root {
  .ant-modal-mask {
    background: rgba(255, 255, 255, 0.33);
    backdrop-filter: blur(5px);
  }

  .ant-modal-wrap {
    display: flex;
    .ant-modal {
      margin: auto;
      top: 0;
      .ant-modal-content {
        border-radius: 10px;
        .ant-modal-header {
          border-radius: 10px 10px 0 0;
          border-bottom: none;
          .ant-modal-title {
            display: flex;
            align-items: center;
          }
        }
        .ant-modal-footer {
          text-align: center;
          padding: 16px;
        }
      }
    }
  }
}
