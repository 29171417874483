@import 'styles/mixins.scss';
@import 'styles/values.scss';

.spaceCard {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  :global {
    .ant-card-body {
      padding: 0px;
    }

    h5.ant-typography {
      color: #2e3945;
      margin-bottom: 0;
    }

    .ant-row {
      justify-content: space-around;
    }
  }
}

.contentRightLimit {
  max-width: 70%;
}

.contentLeftLimit {
  max-width: 30%;
}

.description {
  color: $color-text-light;
}

.icon {
  @include fontSize(48px);
  color: $color-text-dark;
  padding: 6px 32px;
}
