@import 'styles/values.scss';

.wrapper {
  :global {
    .ant-typography {
      margin: 0;
      font-weight: bold;
    }
  }
  h1 {
    font-size: 85px;
    line-height: 52px;
    &:global(.ant-typography) {
      margin-bottom: 12px;
    }
  }
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
  h4 {
    font-size: 40px;
    line-height: 40px;
  }
}
