$shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
.ant-btn:not(.ant-btn-link) {
  border-radius: 5px;
  box-shadow: $shadow;
  &[disabled] {
    border: none;
    box-shadow: $shadow;
    &:hover {
      box-shadow: $shadow;
    }
  }
  &.ant-btn-ghost {
    border-color: #fff;
  }
}

.ant-btn:not(.ant-btn-icon-only) {
  svg {
    vertical-align: middle;
  }
}

.ant-btn:not(.ant-btn-primary) {
  &.green {
    color: #fff;
    background-color: #32dd9a;
  }

  &.orange {
    color: #fff;
    background-color: #ffba44;
  }

  &.red {
    color: #fff;
    background-color: #f7685b;
  }
}
