@import 'styles/mixins.scss';

.wrapper {
  $height: 97px;
  border-radius: 20px;
  box-shadow: 0px 27px 47px -5px #717171;
  width: 460px;
  height: $height;
  max-width: 90vw;
  position: fixed;
  left: 50%;
  bottom: -$height + 22px;
  transform: translateX(-50%);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  :global(.ant-card-head) {
    border: none;
    padding: 0;
    min-height: unset;
  }
  :global(.ant-card-head-title) {
    padding: 0;
    text-align: center;
  }
  &:global(.ant-card-hoverable) {
    &:hover {
      bottom: -$height + 32px;
    }
  }
  &.open {
    bottom: 32px;
  }
}

.arrow {
  color: #2e3a59;
  transition: transform 0.3s ease-out;
  cursor: pointer;
  @include fontSize(14px);
  &Down {
    transform: rotate(180deg);
  }
}
