@import "styles/mixins.scss";

.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    line-height: normal;
    opacity: 0.3;
    transition: all 0.3s;
    display: block;
    padding: calculateRem(13px);

    &:hover {
      opacity: 1;
      border-radius: 3px;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.linkActive {
  // important is needed because 'wrapper a' is stronger than this
  // we can't change to .wrapper .link because antd tooltip is replacing this class
  opacity: 1 !important;
}

.linkIcon {
  @include fontSize(18px);
}
