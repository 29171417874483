@import 'styles/values.scss';
@import 'styles/mixins.scss';
@import 'styles/global.module.scss';

.text {
  text-align: center;
  &:global(.ant-typography) {
    margin: 15px 0;
  }
}

.ico {
  fill: $color-main;
  margin-right: 10px;
  font-size: 30px;
}
