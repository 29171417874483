.button {
  border: none;
  background: none;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  outline: none;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 300px;
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 15px 20px var(--color-shadow);
      background-color: var(--color);
    }
    &:active {
      transform: scale(0.95);
    }
  }
  :global(.ant-skeleton-title) {
    margin: 0;
  }
}

.iconWrap {
  width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
