.scale {
  @mixin on {
    width: 14px;
    transform: scale(1);
  }
  @mixin off {
    width: 0;
    transform: scale(0);
  }
  &Enter {
    @include off;
  }
  &EnterActive {
    @include on;
    transition: all 0.3s cubic-bezier(0.45, 0.38, 0.54, 2.38);
  }
  &Exit {
    @include on;
  }
  &ExitActive {
    @include off;
    transition: all 0.3s ease-out;
  }
}
