@import 'styles/values.scss';

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  &Reverse {
    flex-direction: column-reverse;
  }
  &Grow {
    flex-grow: 1;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: initial;
  &Reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  :global(.ant-typography + h5.ant-typography) {
    margin-top: 3px;
  }
}

.title {
  margin-left: 0.5rem;
  white-space: nowrap;

  &:global(.ant-typography) {
    color: $color-text-dark;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.subtitle {
  @extend .title;
  &:global(.ant-typography) {
    font-size: 12px;
    font-weight: 400;
  }
}

.chaartLegend {
  justify-content: flex-end;
}
