$bubble-size: 93px;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $bubble-size;
  font-size: 12px;
  font-weight: bold;
}

.bubbleBackgroundWrapper {
  width: $bubble-size;
  height: $bubble-size;
}

.label {
  padding-top: 0.5rem;
  text-align: center;
}

.value {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
  position: absolute;
}
