@import 'styles/mixins.scss';
@import 'styles/values.scss';

.locationGroup {
  &:global(.fade-enter) {
    opacity: 0;
  }
  &:global(.fade-enter-active) {
    opacity: 1;
    transition: opacity 300ms;
  }
  &:global(.fade-exit) {
    opacity: 1;
  }
  &:global(.fade-exit-active) {
    opacity: 0;
    transition: opacity 300ms;
  }
}

.selectorPopover {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    border-radius: 20px;
  }

  :global(.ant-popover-inner-content) {
    padding: 0px;
  }
}

.button {
  z-index: 1001;
  border: none;
  width: 57px;
  height: 57px;
  position: fixed;
  bottom: 25px;
  left: 30px;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  &:active {
    transform: scale(0.9);
  }
}

.icon {
  @include fontSize(34px);
  color: $color-main;
}
