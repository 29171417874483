@import 'styles/values.scss';

.title {
  &:global(.ant-typography) {
    font-size: 20px;
    color: $color-text-dark;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.contentWrapper {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
}

.summaryCard {
  &:global(.ant-card) {
    height: 100%;
  }

  :global(.ant-card-body) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.summaryText {
  flex-grow: 1;
}

.blogText {
  font-weight: bold;
  margin-top: auto;
  font-size: 18px;
  a {
    margin: 0 5px;
  }
}
