@import 'styles/values.scss';
@import 'styles/mixins.scss';

.title {
  padding: 18px 0px 0px 0px;
  display: flex;
}

.titleIcon {
  @include fontSize(24px);
  margin-right: 11px;
  color: $color-graphic-medium;
}

.emailTag {
  font-weight: bold;
  color: white;
  background-color: $color-main;
  border: none;

  span {
    svg {
      fill: white;
    }
  }
}

.emailInput > div:first-child {
  width: 80%;
}
