@import 'styles/values.scss';

.card {
  border-radius: 20px;
  border: none;
  -webkit-filter: opacity(1);
  filter: opacity(1);
  -webkit-print-color-adjust: exact;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09),
    0px 8px 32px rgba(204, 188, 252, 0.15);
}
