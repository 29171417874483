@import 'styles/mixins.scss';
@import 'styles/values.scss';

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scrollContainer {
  min-width: 380px;
  @media (max-width: $location-tray-width-breakpoint) {
    min-width: 0px;
  }

  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px 10px 10px 10px;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.angled {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  .floor {
    position: relative;
    pointer-events: none;
  }
}

.floorButton {
  min-height: 35px;
  aspect-ratio: 1;
  padding: 0px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  pointer-events: all;
  &[disabled] {
    border: none;
    background-color: unset;
    cursor: default;
    &:hover {
      background-color: unset;
    }
  }
}

.floorOutline {
  position: absolute;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: all 0.2s;
  cursor: pointer;
  pointer-events: all;

  @media (max-width: $location-tray-width-breakpoint) {
    display: none;
  }

  &.perspective {
    top: -98px;
    left: 100px;
    min-height: 160px;
    min-width: 200px;
    transform: perspective(200px) rotateX(65deg);
  }

  &.angled:not(.perspective) {
    top: -15px;
    left: 60px;
    min-height: 80px;
    min-width: 425px;
    transform: translate(25px, 0px) rotateZ(200deg) skew(-30deg);
  }

  &:hover {
    background: rgb(6, 118, 237, 0.3);
  }

  &.selected {
    background: adjust-color($color-main, $alpha: 0.8);
  }

  &.green {
    background-color: adjust-color($color-floor-green, $alpha: 0.4);

    &:hover,
    &.selected {
      background-color: $color-floor-green;
    }
  }

  &.orange {
    background-color: adjust-color($color-floor-orange, $alpha: 0.4);

    &:hover,
    &.selected {
      background-color: $color-floor-orange;
    }
  }

  &.red {
    background-color: adjust-color($color-floor-red, $alpha: 0.4);

    &:hover,
    &.selected {
      background-color: $color-floor-red;
    }
  }

  &.disabled {
    background-color: rgba(128, 128, 128, 0.03);
    cursor: unset;
    pointer-events: none;
  }
}

.viewOptionsButton {
  position: absolute;
  top: 0px;
  right: 0px;

  @media (max-width: $location-tray-width-breakpoint) {
    display: none;
  }
}
