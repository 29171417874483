@import 'styles/values.scss';

.wrapper {
  width: 100%;
}

.title {
  margin-left: 0.5rem;

  &:global(.ant-typography) {
    font-size: 20px;
    color: $color-text-dark;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
