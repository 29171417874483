@import 'styles/mixins.scss';
@import 'styles/values.scss';

.roomListTitle {
  overflow: hidden;
  padding: 4px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 302px;
}

.roomList {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 8px 5px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.roomListEmpty {
  min-width: 375px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.locationTrayEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.roomCard {
  min-width: 390px;
}
