@import 'styles/values.scss';

.wrapper {
  display: flex;
  text-align: right;
  flex-direction: column;
  color: #ffffff;
  font-size: 10px;
  font-weight: lighter;
  justify-content: flex-end;
  &Reverse {
    text-align: left;
  }
}

.cell {
  box-sizing: border-box;
  padding: 0 0.4rem;
  padding-right: 0.5rem;
  text-align: center;
  border: solid #fff 1px;
  background: $color-text-dark;
  height: var(--value);
  line-height: var(--value);
}
