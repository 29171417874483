@import './modal.scss';
@import './button.scss';
@import './card.scss';
@import './table.scss';
@import './layout.scss';
@import './radio.scss';
@import './popover.scss';
@import './input.scss';

#root {
  height: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: $color-graphic-gray;
  width: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}
