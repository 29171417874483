@import 'styles/values.scss';

.wrapper {
  background: $color-background-white;
  height: 100%;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  overflow: hidden;
  &.wrapperPadding {
    padding: 8vh 0 0;
  }
}

.title {
  &:global(.ant-typography) {
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 7px;
    color: desaturate(lighten($color-main, 30%), 40%);
    line-height: normal;
  }
}

.circle {
  position: fixed;
  border-radius: 50%;
  border: 5px solid desaturate(lighten($color-main, 50%), 40%);
  width: 60vw;
  height: 60vw;
  margin-top: 2vw;
  margin-left: 1vw;
  z-index: 0;
  pointer-events: none;
}

.content {
  overflow: auto;
  padding: 2vh 4vw;
  justify-content: flex-start;
  height: 100%;
  > *:first-child {
    margin-top: auto;
  }
  > *:last-child {
    margin-bottom: auto;
  }
}
