@import 'values.scss';

.fullPageLayout {
  padding: 20px 50px;
}

.statusTag {
  width: 100%;
  max-width: 120px;
  text-align: center;
}

.textRight {
  text-align: right;
}

.fullWidth {
  width: 100%;
}

.fullSizeFlexContainer {
  @extend .fullWidth;
  display: flex;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.center {
  align-items: center;
  justify-content: center;
}

tbody tr.tableRow > td {
  padding: 10px 16px;
}

.cardHeader {
  background-color: $color-background-white;
  border-radius: 5px;
  box-shadow: 1px 2px 4px rgba(96, 94, 94, 0.25);
}

:export {
  colorMain: $color-main;
  colorNoData: $color-no-data;
  backgroundColor: $color-background-gray;
  colorGrid: $color-grid;
}
