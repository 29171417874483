@import '../values.scss';

.ant-radio-group-shadow {
  &.ant-radio-group-outline {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09),
      0px 8px 32px rgba(204, 188, 252, 0.15);
    .ant-radio-button-wrapper {
      border-radius: 20px;
      margin: 5px;
      &:before {
        display: none;
      }
      &:not(.ant-radio-button-wrapper-checked) {
        border-color: #fff;
      }
    }
  }
}
