@import 'styles/mixins.scss';
@import 'styles/values.scss';
@import '../sharedStyles.module.scss';

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - $ls-forms-title-height);
  width: 100%;
  padding-left: 3rem;
}

.floorInputRowWithoutMargins {
  display: flex;
  overflow-y: auto;
  font-weight: 700;

  :global {
    .ant-space-item:first-child {
      flex: 1 1;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &.labels :last-child {
    min-width: 110px;
  }
}

.floorInputRow {
  display: flex;
  margin-bottom: 6px;
  overflow-y: auto;

  :global(.ant-space-item:first-child) {
    flex: 1 1;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  :global(.ant-form-item-explain) {
    display: none;
  }

  &.labels :last-child {
    min-width: 110px;
  }
}
