.tile {
  display:flex;
  width:25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  p {
    margin: 0rem;
  }
}

.tileCategory{
  font-weight: bold;
}
