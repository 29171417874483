@import 'styles/mixins.scss';

.wrapper {
  margin-left: 14px;
  p {
    margin: 0;
    white-space: nowrap;
    &:after {
      content: ' ';
      width: 3px;
      height: 12px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.09);
      display: block;
      margin: 5px 11px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.icon {
  @include fontSize(25px);
  vertical-align: middle;
  margin-right: 15px;
}
