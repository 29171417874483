@import 'styles/values.scss';

$font-size-title: 18px;
$font-size-content: 14px;

$layout-elements-margin: 8px;

.container {
  position: relative;
  overflow-y: scroll;
  padding: 2 * $layout-elements-margin 5mm;
}

.spaceContainer {
  display: flex;
  justify-content: space-between;
  &EqualColumns {
    align-items: stretch;
    > * {
      height: 440px;
      flex: 1;
    }
  }
}

.topContainer {
  :global(.ant-typography) {
    margin: 0;
    font-weight: bold;
  }
  h1 {
    font-size: 32px;
    line-height: 34px;
    &:global(.ant-typography) {
      margin-bottom: 10px;
    }
  }
  h5 {
    font-size: $font-size-content;
    &:global(.ant-typography) {
      margin: 0;
    }
  }
}

.contentRight {
  min-width: 200px;
  text-align: right;
}

.barChartContainer {
  height: 440px;
  :global {
    .ant-card-body,
    .ant-space,
    .ant-space-item:last-child {
      height: 100%;
    }
  }
}

.parametersBreakdownTitle {
  margin-bottom: 30px;
  :global {
    .ant-typography {
      margin: 0;
      padding: 0;
      & + h5 {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
