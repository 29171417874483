@import 'styles/mixins.scss';
@import 'styles/values.scss';
@import '../sharedStyles.module.scss';

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - $ls-forms-title-height);
  width: 100%;
  padding-left: 3rem;
}

.roomInputRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  height: 100%;
  overflow: auto;

  :global(.ant-space-item:first-child) {
    flex: 1 1;
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.floorName[disabled] {
  font-weight: 700;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #eee;
  cursor: default;

  &:hover {
    border-color: #eee;
  }
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
