@import 'styles/mixins.scss';
@import 'styles/values.scss';

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
}

.icon {
  @include fontSize(48px);

  &.small {
    @include fontSize(32px);
  }

  &.large {
    @include fontSize(64px);
  }
}
