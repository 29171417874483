$space: 16px;
$borderStyle: solid rgb(67, 67, 67);

.container {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: $space;
  width: 100%;
}

.borderLeft {
  border-left: $borderStyle;
}

.borderRight {
  border-right: $borderStyle;
}
