@import 'styles/values.scss';

.wrapper {
  display: flex;
  text-align: center;
}

.cell {
  padding: 0.43rem;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 1;
  font-size: 9px;
  color: $color-text-dark;
  flex-basis: var(--value);
  width: var(--value);
  &Grow {
    flex-grow: 1;
  }
}
