.deviceList {
  height: calc(100% - 20px);
  overflow: hidden;

  :global(.ant-table-content) {
    tr {
      td:hover {
        cursor: pointer;
      }
    }
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.tableTop {
  margin-bottom: 20px;
}
