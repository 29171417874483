@import 'styles/values.scss';
@import 'styles/mixins.scss';

.filter {
  position: relative;
  width: 90%;
  margin-bottom: 20px;
  min-height: 50px;

  .select {
    height: 100%;
    width: 80%;
  }

  .frequency {
    display: flex;

    .frequencyTitle {
      padding: 0 15px;
      line-height: 30px;
      font-weight: bold;
      cursor: default;
    }

    .frequencyBtn {
      border: none;
      background-color: 'inherit';
      border-radius: 5px;
    }

    .frequencyBtn:focus-within {
      box-shadow: none;
    }
  }

  .clearBtn {
    display: flex;
    margin-left: 10px;
    transition: all 0.5s ease;
  }
}

.select > div {
  border-radius: 5px;
  width: 80%;
}
