@import 'styles/values.scss';

.modal {
  width: 100px;
}

.ico {
  vertical-align: middle;
  margin-right: 10px;
  fill: $color-main;
}

.icoSuccess {
  @extend .ico;
  fill: $color-floor-green;
}

.space {
  width: 100%;
  align-items: stretch;
  :global(.ant-space-item) {
    &:first-child {
      width: 50%;
    }
    &:last-child {
      width: 50%;
    }
  }
}

.listItem {
  &:global(.ant-list-item) {
    padding: 4px 0;
    justify-content: flex-start;
    border-bottom: none;
  }
}

.attributeName {
  font-weight: bold;
  display: block;
  margin-right: 10px;
  white-space: nowrap;
}

.attributeValue {
  display: block;
}
