.header {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  min-height: 56px;
  padding: 0px 20px;

  > div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    margin-bottom: 0px;
  }

  .search {
    border-radius: 5px;
  }
}
