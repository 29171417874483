@import 'styles/values.scss';
@import 'styles/global.module.scss';

.password {
  @extend .fullWidth;

  div:first-child {
    width: 94%;
  }
}
