@import 'styles/mixins.scss';
@import 'styles/values.scss';

.tabs {
  position: relative;
  margin-top: 0.5em;

  @media (max-width: $location-tray-width-breakpoint) {
    margin-top: 0.75em;
  }
}

.locationEditTitle {
  margin-bottom: 0;
}

.locationEditEmpty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: form;
}

.emptyAddButton {
  @media (min-width: $location-tray-width-breakpoint) {
    display: none;
  }
}

.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
