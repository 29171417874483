@import 'styles/values.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.item {
  display: flex;
  align-items: center;
  margin: 0;
}

.figure {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  margin-right: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
