@import 'views/Settings/styles.module.scss';

.columnLeft {
  padding-bottom: 5px;
}

.columnCenter {
  text-align: center;
}

.card {
  margin-bottom: $content-margin;
}

.buttonWrapper {
  margin-top: $content-margin * 2;
  text-align: center;
  pointer-events: none;
  button {
    pointer-events: auto;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.09),
      0px 8px 32px rgba(204, 188, 252, 0.15);
  }
}
