@import 'styles/mixins.scss';
@import 'styles/values.scss';

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.card {
  &:global(.ant-card) {
    width: 100%;
  }
  :global(.ant-card-body) {
    padding: 15px 24px;
    display: flex;
    align-items: center;
  }

  :global(.ant-image) {
    width: 43px;
    min-width: 43px;
    height: 43px;
    margin-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    svg {
      width: 100%;
      font-size: 24px;
      color: lighten($color-main, 35%);
    }

    :global(.ant-image-placeholder) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(100% - 60px);

    *:global(.ant-typography) {
      font-size: 14px;
      color: $color-text-dark;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }

    .labelsContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      .rightText {
        white-space: nowrap;
        &:global(.ant-typography) {
          color: $color-text-dark;
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }
}

.button {
  margin-left: 10px;
  color: $color-text-dark;
  min-width: 32px;
}
