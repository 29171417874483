@import 'styles/values.scss';

.title {
  &:global(.ant-typography) {
    color: $color-text-dark;
  }
}

.titleUnit {
  margin-left: 0.2rem;
  font-size: 1rem;
  font-weight: bold;
}

.container {
  width: 100%;
}

.chartWrapper {
  height: 57px;
  margin-bottom: 15px;
}
