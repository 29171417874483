@import 'styles/values';

$border-radius: 4px;

.container {
  background-color: $color-background-white;
  padding: 7px 12px;
  border: 1px solid $color-border-gray;
  border-radius: $border-radius;
}
