@import 'styles/mixins.scss';
@import 'styles/values.scss';
@import '../sharedStyles.module.scss';

.form {
  display: flex;
  flex-direction: column;
  height: calc(100% - $ls-forms-title-height);
  width: 100%;
  padding-left: 3rem;
  &:global(.ant-typography) {
    margin-bottom: 6px;
    color: $color-text-dark;
  }
}

.buildingInput {
  height: 100%;
  overflow: auto;
}

.streetAddress {
  text-align: start;

  button {
    padding-left: 0px;
  }
}

.latLngFormItem {
  margin-bottom: 0;
}

.latLngInput {
  width: unset;
}

.addressSearch {
  min-width: 100%;

  @media (max-width: $location-tray-width-breakpoint) {
    max-width: unset;
  }
}
