$color-main: #0177ee;
$color-danger: rgb(247, 104, 91);
$color-success: rgb(50, 221, 154);

$color-background-gray: #f0f2f5;
$color-background-white: #fff;

$color-floor-selected: $color-main;
$color-floor-green: $color-success;
$color-floor-orange: rgb(255, 186, 66);
$color-floor-red: $color-danger;

$color-graphic-light: rgb(219, 232, 247);
$color-graphic-medium: rgb(119, 182, 254);
$color-graphic-dark: rgb(64, 146, 205);
$color-graphic-gray: rgb(240, 242, 245);

$color-text-dark: rgba(46, 57, 69, 1);
$color-text-light: rgba(46, 57, 69, 0.4);

$color-border-gray: #dae1ed;

$color-no-data: #c6c6c6;

$location-tray-width-breakpoint: 980px;

$color-grid: #ddecfa;
