.toolbar {
  position: fixed;
  z-index: 401;
}

.top {
  top: 80px;
}

.bottom {
  bottom: 28px;

  &.left {
    left: 100px;
  }

  &.right {
    right: 100px;
  }
}

.left {
  left: 30px;
}

.right {
  right: 30px;
}
