@import 'styles/values.scss';
@import 'styles/mixins.scss';

$color-tab-base: #d9d9d9;
$color-tab-active: #000000;
$color-tab-disabled: #f2f2f2;
$color-tab-hover: #5d5d5d;

.tabsContainerDefault {
  :global {
    .ant-tabs-nav {
      &:before {
        border: none;
      }
    }
  }
}

.tabsContainer {
  padding-top: 24px;
  padding-right: 16px;

  span[role='img'] {
    @include fontSize(16px);
    width: 100%;
    padding: 0px 4px;
  }

  :global {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        color: $color-tab-base;
        padding: 4px 0;
        margin: 0;
      }

      &:hover {
        color: $color-tab-hover;
      }

      .ant-tabs-tab-disabled {
        color: $color-tab-disabled;

        &:hover {
          color: $color-tab-disabled;
        }
      }

      .ant-tabs-tab-active {
        background-color: $color-graphic-gray;
        border: $color-tab-base 1px solid;

        * {
          color: $color-tab-active;
        }
      }

      .ant-tabs-ink-bar {
        background-color: white;
        color: white;
      }
    }
  }
}
