.cell {
  border: 1px solid #fff;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 0px;
  font-size: 9px;
  color: var(--fontColor);
  font-weight: lighter;
  height: var(--height);
  line-height: var(--height);
  background: var(--background);
  flex-grow: 1;
  flex-basis: 0;

  &Clickable {
    cursor: pointer;
  }

  &Square {
    flex-grow: 0;
    flex-basis: var(--height);
  }
}
